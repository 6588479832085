import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from, throwError, BehaviorSubject } from 'rxjs';
import { share, map, tap, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseApiUrl: string = environment.baseApiUrl;
  apiAuth: string = environment.apiAuth;
  apiPass: string = environment.apiPass;

  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '1';

  httpFormOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
    })
  };

  constructor(private http: HttpClient, private router: Router) {}

  getRoutes() {

    const form: FormData = new FormData();

    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
    form.set('lang', lang);
    form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}route/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        resData.data = resData.data.filter(function( route ) {
            return route.etat !== 0;
        });
        resData.data.forEach(function(element){
          element.created_at = new Date(element.created_at).toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          element.updated_at = new Date(element.updated_at).toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          element.activePoints = element.points.filter((point) => point.etat === 2).length + element.variations.length;
        });
        return resData.data;
      })
    );
  }

  getRoute(route_id) {

    const form: FormData = new FormData();
    form.set('route_id', route_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}route/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        resData.data.created_at = new Date(resData.data.created_at).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        resData.data.updated_at = new Date(resData.data.updated_at).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        resData.data.activePoints = resData.data.points.filter((point) => point.etat === 2).length + resData.data.variations.length;
        return resData.data;
      })
    );
  }

  addRoute(title: string, options?: {route_id?: string, description?: string, youtube?:string, medias?: any, etat?: string, trace?: string, theme?: string, time?: string, distance?: string, content?: string, blocking_geolocation?: any, start_step_blocking_geolocation?: any, end_step_blocking_geolocation?: any}) {

    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    const form: FormData = new FormData();
    form.set('user_id', this.userID);
    form.set('lang', lang);
    form.set('title', title);
    if (options.route_id) {
      form.set('route_id', options.route_id);
    }
    if (options.description) {
      form.set('description', options.description);
    }
    if (options.youtube) {
      form.set('youtube', options.youtube);
    } else {
      form.set('youtube', '');
    }
    if (options.medias) {
      let i;
      for (i = 0; i < options.medias.length; i++) {
        if (!options.medias[i].name) {
          form.set(options.medias[i].size, options.medias[i]);
        } else {
          form.set(options.medias[i].name, options.medias[i]);
        }
      }
    }
    if (options.etat) {
      form.set('etat', options.etat);
    }
    if (options.trace) {
      form.set('trace', options.trace);
    }
    if (options.theme) {
      form.set('theme', options.theme);
    }
    if (options.time) {
      form.set('time', options.time);
    }
    if (options.distance) {
      form.set('distance', options.distance);
    }
    if (options.content) {
      form.set('content', options.content);
    }
    if (options.blocking_geolocation)  form.set('blocking_geolocation', options.blocking_geolocation);
    if (options.start_step_blocking_geolocation)  form.set('start_step_blocking_geolocation', options.start_step_blocking_geolocation);
    if (options.end_step_blocking_geolocation)  form.set('end_step_blocking_geolocation', options.end_step_blocking_geolocation);

    return this.http.post<any>(
      `${environment.baseApiUrl}route/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPoints(options?: {user_id?: string, route_id?: string, article_id?: string}) {
    
    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('lang', lang);

    if (options) {
      if (options.route_id) {
        form.set('route_id', options.route_id);
      } else if (options.article_id) {
        form.set('article_id', options.article_id);
      }
      if (options.user_id) form.set('user_id', options.user_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}point/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPointsLight(options?: {user_id?: string}) {
    
    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('lang', lang);

    if (options) {
      if (options.user_id) form.set('user_id', options.user_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}point/list_light`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        resData.data = resData.data.filter(function( point ) {
          return point.etat == 2;
        });
      })
    );
  }

  getPoint(point_id: string) {
    const form: FormData = new FormData();
    form.set('point_id', point_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}point/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deletePoint(user_id: string, point_id: string, variation_id?: string) {
    const form: FormData = new FormData();

    form.set('user_id', user_id);
    form.set('point_id', point_id);
    if (variation_id) form.set('variation_id', variation_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}point/delete`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  unlinkPoint(point_id: string, options?: {route_id?: string, article_id?: string, variation_id?: string}) {
    
    const form: FormData = new FormData();
    form.set('point_id', point_id);
    (options.variation_id) ? form.set('variation_id', options.variation_id) : form.set('variation_id', 'null');

    if (options.route_id) form.set('route_id', options.route_id);
    if (options.article_id) form.set('article_id', options.article_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}point/unlink`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addPoint(lat: string, lon: string, title: string, options?: {route_id?: string, article_id?: string, point_id?: string, catpoint_id?: string, googleplace_id?: string, description?:string, moreinfo?: string, youtube?: string, medias?: any, etat?: string, order?: string, statut_edit?: string, video_codes?: any, city?: any, url?: string}) {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('user_id', this.userID);
    form.set('lang', lang);
    form.set('lat', lat);
    form.set('lon', lon);
    form.set('title', title);

    if (options.route_id) form.set('route_id', options.route_id);
    if (options.article_id) form.set('article_id', options.article_id);
    if (options.point_id) form.set('point_id', options.point_id);
    if (options.catpoint_id) form.set('catpoint_id', options.catpoint_id);
    if (options.googleplace_id) form.set('googleplace_id', options.googleplace_id);
    if (options.description) form.set('description', options.description);
    if (options.moreinfo) form.set('moreinfo', options.moreinfo);
    if (options.youtube) form.set('youtube', options.youtube);
    if (options.etat) form.set('etat', options.etat);
    if (options.order) form.set('order', options.order);
    if (options.statut_edit) form.set('statut_edit', options.statut_edit);
    if (options.video_codes) form.set('video_codes', JSON.stringify(options.video_codes));
    if (options.city) form.set('city', options.city);
    if (options.url) form.set('url', options.url);

    if (options.medias) {
      let i;
      for (i = 0; i < options.medias.length; i++) {
        if (!options.medias[i].name) {
          form.set(options.medias[i].size, options.medias[i]);
        } else {
          form.set(options.medias[i].name, options.medias[i]);
        }
      }
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}point/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

    editPoint(point_id: any, options?: {lat?: string, lon?: string, title?: string, catpoint_id?: string, googleplace_id?: string, description?:string, moreinfo?: string, youtube?: string, etat?: string, city?: any, url?: string}) {
    const form: FormData = new FormData();

    form.set('user_id', this.userID);
    form.set('point_id', point_id);

    if (options.lat) form.set('lat', options.lat);
    if (options.lon) form.set('lon', options.lon);
    if (options.title) form.set('title', options.title);
    if (options.catpoint_id) form.set('catpoint_id', options.catpoint_id);
    if (options.googleplace_id) form.set('googleplace_id', options.googleplace_id);
    if (options.description) form.set('description', options.description);
    if (options.moreinfo) form.set('moreinfo', options.moreinfo);
    if (options.youtube) form.set('youtube', options.youtube);
    if (options.etat) form.set('etat', options.etat);
    if (options.city) form.set('city', options.city);
    if (options.url) form.set('url', options.url);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/point/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  pointUnlinkVideos(point_id: any, video_codes: any) {
    const form: FormData = new FormData();
    form.set('point_id', point_id);
    form.set('video_codes', JSON.stringify(video_codes));

    return this.http.post<any>(
      `${environment.baseApiUrl}point/unlink_videocodes`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  articleUnlinkVideos(article_id: any, video_codes: any) {
    const form: FormData = new FormData();
    form.set('article_id', article_id);
    form.set('video_codes', JSON.stringify(video_codes));

    return this.http.post<any>(
      `${environment.baseApiUrl}article/unlink_videocodes`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addVariation(point_id: string, title: string, lat: string, lon: string, options?: {route_id?: string, article_id?: string, variation_id?: string, catpoint_id?: string, description?:string, moreinfo?: string, youtube?: string, etat?: any, medias?: any, order?: string, city?: any, video_codes?: any, url?: string}) {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('user_id', this.userID);
    form.set('point_id', point_id);
    form.set('lang', lang);
    form.set('title', title);
    form.set('lat', lat);
    form.set('lon', lon);

    if (options.route_id) form.set('route_id', options.route_id);
    if (options.article_id) form.set('article_id', options.article_id);
    if (options.variation_id) form.set('variation_id', options.variation_id);
    if (options.catpoint_id) form.set('catpoint_id', options.catpoint_id);
    if (options.description) form.set('description', options.description);
    if (options.moreinfo) form.set('moreinfo', options.moreinfo);
    if (options.youtube) form.set('youtube', options.youtube);
    if (options.etat) form.set('etat', options.etat);
    if (options.order) form.set('order', options.order);
    if (options.video_codes) form.set('video_codes', JSON.stringify(options.video_codes));
    if (options.city) form.set('city', options.city);
    if (options.url) form.set('url', options.url);

    if (options.medias) {
      let i;
      for (i = 0; i < options.medias.length; i++) {
        if (!options.medias[i].name) {
          form.set(options.medias[i].size, options.medias[i]);
        } else {
          form.set(options.medias[i].name, options.medias[i]);
        }
      }
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}variation/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editVariation(variation_id: any, options?: {lat?: string, lon?: string, title?: string, catpoint_id?: string, googleplace_id?: string, description?:string, moreinfo?: string, youtube?: string, etat?: string, city?: any, url?: string}) {
    const form: FormData = new FormData();

    form.set('user_id', this.userID);
    form.set('variation_id', variation_id);

    if (options.lat) form.set('lat', options.lat);
    if (options.lon) form.set('lon', options.lon);
    if (options.title) form.set('title', options.title);
    if (options.catpoint_id) form.set('catpoint_id', options.catpoint_id);
    if (options.googleplace_id) form.set('googleplace_id', options.googleplace_id);
    if (options.description) form.set('description', options.description);
    if (options.moreinfo) form.set('moreinfo', options.moreinfo);
    if (options.youtube) form.set('youtube', options.youtube);
    if (options.etat) form.set('etat', options.etat);
    if (options.city) form.set('city', options.city);
    if (options.url) form.set('url', options.url);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/variation/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  variationUnlinkVideos(variation_id: any, video_codes: any) {
    const form: FormData = new FormData();
    form.set('variation_id', variation_id);
    form.set('video_codes', JSON.stringify(video_codes));

    return this.http.post<any>(
      `${environment.baseApiUrl}variation/unlink_videocodes`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getVariation(variation_id: string) {
    const form: FormData = new FormData();
    form.set('variation_id', variation_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}variation/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getVariations(options?: {point_id?: string, user_id?: string}) {
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    const form: FormData = new FormData();
    if (options) {
      if (options.point_id) form.set('point_id', options.point_id);
      if (options.user_id) form.set('user_id', options.user_id);  
    }
    form.set('lang', lang);

    return this.http.post<any>(
      `${environment.baseApiUrl}variation/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  orderRoutePoints(route_id: string, points: any) {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('user_id', this.userID);
    form.set('lang', lang);
    form.set('route_id', route_id);
    form.set('points', JSON.stringify(points));

    return this.http.post<any>(
      `${environment.baseApiUrl}route/point/order`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  orderPublicationPoints(article_id: string, points: any) {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('user_id', this.userID);
    form.set('lang', lang);
    form.set('article_id', article_id);
    form.set('points', JSON.stringify(points));

    return this.http.post<any>(
      `${environment.baseApiUrl}article/point/order`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPublications(options?: {point_id?: string, variation_id?: string}) {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('user_id', this.userID);
    form.set('lang', lang);

    if (options) {
      if (options.point_id) form.set('point_id', options.point_id);
      if (options.variation_id) form.set('variation_id', options.variation_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}article/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        resData.data = resData.data.filter(function( article ) {
          return article.etat !== 0;
        });
        resData.data.forEach(function(element){
          element.created_at = new Date(element.created_at).toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          element.updated_at = new Date(element.updated_at).toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          element.activePoints = element.points.filter((point) => point.etat === 2).length + element.variations.length;
          element.mediaCount = element.medias.length;
        });
        return resData.data;
      })
    );
  }

  getPublication(article_id) {

    const form: FormData = new FormData();
    form.set('article_id', article_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}article/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        resData.data.created_at = new Date(resData.data.created_at).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        resData.data.updated_at = new Date(resData.data.updated_at).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        resData.data.activePoints = resData.data.points.filter((point) => point.etat === 2).length + resData.data.variations.length;
        resData.data.mediaCount = resData.data.medias.length;
        return resData.data;
      })
    );
  }

  addPublication(title: string, options?: {article_id?: string, description?: string, medias?: any, etat?: string, url?: string, audio?: string, video?: string, video_codes?: any, published_at?: string, is_sponso?: boolean, order_sponso?: string}) {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('user_id', this.userID);
    form.set('lang', lang);
    form.set('title', title);
     
    if (options) {
      if (options.article_id) form.set('article_id', options.article_id);
      if (options.description) form.set('description', options.description);
      if (options.etat) form.set('etat', options.etat);
      if (options.url) form.set('url', options.url);
      if (options.audio) form.set('audio', options.audio);
      if (options.video) form.set('video', options.video);
      if (options.video_codes) form.set('video_codes', JSON.stringify(options.video_codes));
      if (options.published_at) form.set('published_at', options.published_at);
      if (options.is_sponso) {
        form.set('is_sponso', '1');
      } else {
        form.set('is_sponso', '0');
      }
      if (options.order_sponso) {
        if ((options.order_sponso !== 'null') && (options.order_sponso !== 'undefined')) {
          form.set('order_sponso', options.order_sponso);  
        }
      }

      if (options.medias) {
        let i;
        for (i = 0; i < options.medias.length; i++) {
          if (!options.medias[i].name) {
            form.set(options.medias[i].size, options.medias[i]);
          } else {
            form.set(options.medias[i].name, options.medias[i]);
          }
        }
      }
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}article/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addConfig(apptitle: string, options?: {config_id?: string, description?: string, colorp?: string, colora?: string, color_txtp?: string, color_txta?: string, color_link?: string, color_nav?: string, geo_value?: string, date_value?: string}) {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('lang', lang);
    form.set('apptitle', apptitle);

    if (options.config_id) form.set('config_id', options.config_id);
    if (options.description) form.set('description', options.description);
    if (options.colorp) form.set('colorp', options.colorp);
    if (options.colora) form.set('colora', options.colora);
    if (options.color_txtp) form.set('color_txtp', options.color_txtp);
    if (options.color_txta) form.set('color_txta', options.color_txta);
    if (options.color_link) form.set('color_link', options.color_link);
    if (options.color_nav) form.set('color_nav', options.color_nav);
    if (options.geo_value) form.set('geo_value', options.geo_value);
    if (options.date_value) form.set('date_value', options.date_value);

    return this.http.post<any>(
      `${environment.baseApiUrl}config/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listConfig(options?: {apptitle?: string}) {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('lang', lang);

    if (options) {
      if (options.apptitle) form.set('apptitle', options.apptitle);  
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}config/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addCatpoint(name: string, options?: {catpoint_id?: string, description?: string, color?: string, medias?: any}) {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('lang', lang);
    form.set('name', name);

    if (options.catpoint_id) form.set('catpoint_id', options.catpoint_id);
    if (options.description) form.set('description', options.description);
    if (options.color) form.set('color', options.color);

    if (options.medias) {
      let i;
      for (i = 0; i < options.medias.length; i++) {
        if (!options.medias[i].name) {
          form.set(options.medias[i].size, options.medias[i]);
        } else {
          form.set(options.medias[i].name, options.medias[i]);
        }
      }
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}catpoint/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listCatpoint() {

    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('lang', lang);

    return this.http.post<any>(
      `${environment.baseApiUrl}catpoint/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteCatpoint(catpoint_id: string) {

    const form: FormData = new FormData();
    form.set('catpoint_id', catpoint_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}catpoint/delete`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  linkMediasPoints(media_points: any) {

    const form: FormData = new FormData();
    form.set('media_points', JSON.stringify(media_points));

    return this.http.post<any>(
      `${environment.baseApiUrl}media/media_point`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  unlinkMedia(media_id: any) {

    const form: FormData = new FormData();
    form.set('media_id', media_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}media/media_unlink`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getLangElements(options?: {lang?: string}) {
    const form: FormData = new FormData();
    if (options) {
      if (options.lang) form.set('lang', options.lang);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}lang_element/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addLangElement(name: string, lang: string, content: string, options?: {lang_element_id?: string}) {
    const form: FormData = new FormData();

    form.set('name', name);
    form.set('lang', lang);
    form.set('content', content);

    if (options) {
      if (options.lang_element_id) form.set('lang_element_id', options.lang_element_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}lang_element/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addLang(name, code_lang, lang_id?) {

    const form: FormData = new FormData();
    form.set('name', name);
    form.set('code_lang', code_lang);
    if (lang_id) form.set('lang_id', lang_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}lang/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getLangs() {
    return this.http.post<any>(
      `${environment.baseApiUrl}lang/list`,
      '',
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listUsers() {
    const form: FormData = new FormData();

    return this.http.post<any>(
      `${environment.baseApiUrl}user/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteUser(user_id: string) {
    const form: FormData = new FormData();
    form.set('user_id', user_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}user/delete`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }


  listAccounts() {
    const form: FormData = new FormData();

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/account/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editAccount(email, password) {
    const form: FormData = new FormData();

    form.set('email', email);
    form.set('password', password);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  duplicateRoute(lang: string, route_id: string) {
    const form: FormData = new FormData();
    form.set('lang', lang);
    form.set('route_id', route_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}route/duplicate`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getGlobalStats(start?: string, end?: string) {
    const form: FormData = new FormData();
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/total`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getUserStats(start?: string, end?: string) {
    const form: FormData = new FormData();
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/get_user`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getVuesStats(start?: string, end?: string) {
    const form: FormData = new FormData();
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/get_vues`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getVueStats(vue: string, start?: string, end?: string) {
    const form: FormData = new FormData();
    form.set('vue', vue);
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/get_vue`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getTrajetStats(trajet_id: any, start?: string, end?: string) {
    const form: FormData = new FormData();
    form.set('trajet_id', trajet_id);
    
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/stats/get_trajets`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getArticleStats(article_id: any, start?: string, end?: string) {
    const form: FormData = new FormData();
    form.set('article_id', article_id);
    
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/stats/get_article`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getDevicesStats(start?: string, end?: string) {
    const form: FormData = new FormData();
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/get_devices`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getTopVues(start?: string, end?: string) {
    const form: FormData = new FormData();
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/get_top10vues`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getTopArticles(start?: string, end?: string) {
    const form: FormData = new FormData();
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/get_top10articles`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getTopRoutes(start?: string, end?: string) {
    const form: FormData = new FormData();
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/get_top10routes`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getTopParcours(start?: string, end?: string) {
    const form: FormData = new FormData();
    if (start) form.set('start', start);
    if (end) form.set('end', end);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/get_top10routestart`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getMapStats () {
    const form: FormData = new FormData();

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/get_usermaps`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }


  addQuizz(trajet_id: any, point_id: any, statut: any, options?: {is_blocking?: any, variation_id?: any, min_score?: any}) {
    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('user_id', this.userID);
    form.set('trajet_id', trajet_id);
    form.set('point_id', point_id);
    form.set('lang', lang);
    form.set('statut', statut);
    if (options) {
      if (options.is_blocking) form.set('is_blocking', options.is_blocking);
      if (options.variation_id) form.set('variation_id', options.variation_id);
      if (options.min_score) form.set('min_score', options.min_score);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editQuizz(quizz_id: any, options: {is_blocking?: any, statut?: any, min_score?: any}) {
    const form: FormData = new FormData();
    form.set('user_id', this.userID);
    form.set('quizz_id', quizz_id);
    if (options) {
      (options.is_blocking) ? form.set('is_blocking', options.is_blocking) : form.set('is_blocking', '0');
      if (options.statut) form.set('statut', options.statut);
      (options.min_score) ? form.set('min_score', options.min_score) : form.set('min_score', '0');
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  duplicateQuizz(quizz_id: any, trajet_id: any, point_id: any, options: {variation_id?: any}) {
    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('user_id', this.userID);
    form.set('lang', lang);
    form.set('quizz_id', quizz_id);
    form.set('trajet_id', trajet_id);
    form.set('point_id', point_id);
    if (options) {
      if (options.variation_id) form.set('variation_id', options.variation_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/duplicate`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteQuizz(quizz_id: any) {
    const form: FormData = new FormData();
    form.set('user_id', this.userID);
    form.set('quizz_id', quizz_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/delete`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listQuizz(trajet_id: any, options?: {point_id?: any}) {
    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('lang', lang);
    form.set('user_id', this.userID);
    form.set('trajet_id', trajet_id);
    if (options) {
      if (options.point_id) form.set('point_id', options.point_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }
  
  getQuizz(quizz_ids: any) {
    const form: FormData = new FormData();

    form.set('user_id', this.userID);
    form.set('quizz_ids', quizz_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addQuizzQuestion(quizz_id: any, title: any, description: any, quizz_type: any, options?: {solution?: any, title_answer?: any, description_answer?: any, title_wrong_answer_false?: any, description_wrong_answer_false?: any, score?: any, key?: any, need_key?: any, letter?: any, order?: any, question_media?: any, answer_media?: any, wrong_answer_media?: any}) {
    const form: FormData = new FormData();
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';

    form.set('user_id', this.userID);
    form.set('title', title);
    form.set('quizz_id', quizz_id);
    form.set('lang', lang);
    form.set('description', description);
    form.set('quizz_type', quizz_type);
    if (options) {
      if (options.solution) form.set('solution', options.solution);
      if (options.title_answer) form.set('title_answer', options.title_answer);
      if (options.description_answer) form.set('description_answer', options.description_answer);
      if (options.title_wrong_answer_false) form.set('title_wrong_answer_false', options.title_wrong_answer_false);
      if (options.description_wrong_answer_false) form.set('description_wrong_answer_false', options.description_wrong_answer_false);
      if (options.score) form.set('score', options.score);
      if (options.key) form.set('key', options.key);
      if (options.need_key) form.set('need_key', options.need_key);
      if (options.letter) form.set('letter', options.letter);
      if (options.order) form.set('order', options.order);
      if (options.question_media) form.set('question_media', options.question_media);
      if (options.answer_media) form.set('answer_media', options.answer_media);
      if (options.wrong_answer_media) form.set('wrong_answer_media', options.wrong_answer_media);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/questions/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editQuizzQuestion(quizzquestion_id: any, title: any, options?: {quizz_type?: any, description?: any, solution?: any, title_answer?: any, description_answer?: any, title_wrong_answer_false?: any, description_wrong_answer_false?: any, score?: any, key?: any, need_key?: any, letter?: any, order?: any, question_media?: any, answer_media?: any, wrong_answer_media?: any}) {
    const form: FormData = new FormData();

    form.set('user_id', this.userID);
    form.set('title', title);
    form.set('quizzquestion_id', quizzquestion_id);
    if (options) {
      if (options.quizz_type) form.set('quizz_type', options.quizz_type);
      if (options.description) form.set('description', options.description);
      if (options.solution) form.set('solution', options.solution);
      if (options.title_answer) form.set('title_answer', options.title_answer);
      if (options.description_answer) form.set('description_answer', options.description_answer);
      if (options.title_wrong_answer_false) form.set('title_wrong_answer_false', options.title_wrong_answer_false);
      if (options.description_wrong_answer_false) form.set('description_wrong_answer_false', options.description_wrong_answer_false);
      (options.score) ? form.set('score', options.score) : form.set('score', '0');
      (options.key) ? form.set('key', options.key) : form.set('key', '0');
      (options.letter) ? form.set('letter', options.letter) : form.set('letter', '');
      if (options.order) form.set('order', options.order);
      if (options.question_media) form.set('question_media', options.question_media);
      if (options.answer_media) form.set('answer_media', options.answer_media);
      if (options.wrong_answer_media) form.set('wrong_answer_media', options.wrong_answer_media);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/questions/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteQuizzQuestion(quizz_question_id: any) {
    const form: FormData = new FormData();

    form.set('user_id', this.userID);
    form.set('quizz_question_id', quizz_question_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/questions/delete`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteQuizzQuestionMedia(quizzquestion_id: any, media_name: any) {
    const form: FormData = new FormData();
    form.set('user_id', this.userID);
    form.set('quizzquestion_id', quizzquestion_id);
    form.set('media_name', media_name);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/question/medias/delete`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addQuizzQuestionOption(quizzquestion_id: any, title: any, is_solution: any, order: any) {
    const form: FormData = new FormData();

    form.set('user_id', this.userID);
    form.set('title', title);
    form.set('quizzquestion_id', quizzquestion_id);
    form.set('is_solution', is_solution);
    form.set('order', order);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/question/options/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editQuizzQuestionOption(quizzquestionoption_id: any, options?: {title: any, is_solution: any, order: any}) {
    const form: FormData = new FormData();

    form.set('user_id', this.userID);
    form.set('quizzquestionoption_id', quizzquestionoption_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      (options.is_solution) ? form.set('is_solution', options.is_solution) : form.set('is_solution', '0');
      if (options.order) form.set('order', options.order);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/question/options/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteQuizzQuestionOption(quizzquestionoption_id: any) {
    const form: FormData = new FormData();

    form.set('user_id', this.userID);
    form.set('quizzquestionoption_id', quizzquestionoption_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/question/options/delete`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  exportUsers() {

    const form: FormData = new FormData();
    form.set('user_id', this.userID);

    let httpFormOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
      }),
      responseType: 'text' as any
    };

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/account/export`,
      form,
      httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        console.log(errorRes);
        
        const errorMessage = errorRes.error;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }



  addArticlesTemplate(etat: any, title: any, options?: {position_title?: any, subtitle?: any, bandeau?: any, logo?: any, position_logo?: any}) {
    const form: FormData = new FormData();
    
    form.set('user_id', this.userID);
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
    form.set('lang', lang);
    form.set('etat', etat);
    form.set('title', title);
    if (options) {
      if (options.position_title) form.set('position_title', options.position_title);
      if (options.subtitle) form.set('subtitle', options.subtitle);
      if (options.bandeau) form.set('bandeau', options.bandeau);
      if (options.logo) form.set('logo', options.logo);
      if (options.position_logo) form.set('position_logo', options.position_logo);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/template/articles/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editArticlesTemplate(template_id: any, options?: {etat?: any, title?: any, position_title?: any, subtitle?: any, bandeau?: any, logo?: any, position_logo?: any}) {
    const form: FormData = new FormData();
    
    form.set('user_id', this.userID);
    form.set('template_id', template_id);
  
    if (options) {
      if (options.etat) form.set('etat', options.etat);
      if (options.position_title) form.set('position_title', options.position_title);
      (options.title) ? form.set('title', options.title) : form.set('title', ' ');
      (options.subtitle) ? form.set('subtitle', options.subtitle) : form.set('subtitle', ' ');
      (options.bandeau) ? form.set('bandeau', options.bandeau) : form.set('bandeau', ' ');
      (options.logo) ? form.set('logo', options.logo) : form.set('logo', ' ');
      if (options.position_logo) form.set('position_logo', options.position_logo);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/template/articles/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getArticlesTemplate() {
    const form: FormData = new FormData();
    
    form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/template/articles/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }


  addTrajetsTemplate(etat: any, title: any, options?: {position_title?: any, subtitle?: any, bandeau?: any, logo?: any, position_logo?: any}) {
    const form: FormData = new FormData();

    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
    form.set('lang', lang);
    form.set('user_id', this.userID);

    form.set('etat', etat);
    form.set('title', title);
    if (options) {
      if (options.position_title) form.set('position_title', options.position_title);
      if (options.subtitle) form.set('subtitle', options.subtitle);
      if (options.bandeau) form.set('bandeau', options.bandeau);
      if (options.logo) form.set('logo', options.logo);
      if (options.position_logo) form.set('position_logo', options.position_logo);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/template/trajets/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editTrajetsTemplate(template_id: any, options?: {etat?: any, title?: any, position_title?: any, subtitle?: any, bandeau?: any, logo?: any, position_logo?: any}) {
    const form: FormData = new FormData();
    
    form.set('user_id', this.userID);
    form.set('template_id', template_id);
  
    if (options) {
      if (options.etat) form.set('etat', options.etat);
      if (options.position_title) form.set('position_title', options.position_title);
      (options.title) ? form.set('title', options.title) : form.set('title', ' ');
      (options.subtitle) ? form.set('subtitle', options.subtitle) : form.set('subtitle', ' ');
      (options.bandeau) ? form.set('bandeau', options.bandeau) : form.set('bandeau', ' ');
      (options.logo) ? form.set('logo', options.logo) : form.set('logo', ' ');
      if (options.position_logo) form.set('position_logo', options.position_logo);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/template/trajets/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getTrajetsTemplate() {
    const form: FormData = new FormData();
    
    form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/template/trajets/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addMenuItem(name: any, url: any, blank: any, inapp: any, order: any) {
    const form: FormData = new FormData();
    
    form.set('user_id', this.userID);
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
    form.set('lang', lang);

    form.set('name', name);
    form.set('url', url);
    (blank) ? form.set('blank', '1') : form.set('blank', '0');
    (inapp) ? form.set('inapp', '1') : form.set('inapp', '0');
    form.set('order', order);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/nav_elements/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editMenuItem(nav_element_id: any, options?: {name?: any, url?: any, blank: any, inapp: any, order: any}) {
    const form: FormData = new FormData();

    form.set('nav_element_id', nav_element_id);
    
    form.set('user_id', this.userID);
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
    form.set('lang', lang);

    if (options) {
      if (options.name) form.set('name', options.name);
      if (options.url) form.set('url', options.url);
      if (options.order) form.set('order', options.order);
      (options.blank) ? form.set('blank', '1') : form.set('blank', '0');
      (options.inapp) ? form.set('inapp', '1') : form.set('inapp', '0');
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/nav_elements/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteMenuItem(nav_element_id: any) {
    const form: FormData = new FormData();

    form.set('nav_element_id', nav_element_id);
    form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/nav_elements/delete`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listMenuItem() {
    const form: FormData = new FormData();

    form.set('user_id', this.userID);
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
    form.set('lang', lang);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/nav_elements/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

}
